const ENDPOINT = '/api/manage-talents/gdpr';

export const useGDPR = () => {
    const hasConsent = () =>
        fetchOrRedirect<{ hasConsent: boolean }>(`${ENDPOINT}/hasConsent`);

    return {
        hasConsent,
    };
};
