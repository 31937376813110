import type { ExternalUser } from '@b2b-platform/auth/entities/user';

import type { Contract, ValidLicenseValue } from '../types/contract';

const ENDPOINT = '/api/manage-talents/contract';

const contract = ref<Contract | null>(null);
const hasProductContingentAvailable = ref<boolean>(false);
const contingentLoading = ref<boolean>(false);

const user = ref<ExternalUser | null>(null);
const {
    PRODUCT_TYPE_CONTINGENT,
    PRODUCT_TYPE_SINGLE,
    PRODUCT_TYPE_MULTI,
    PRODUCT_TYPE_POWER,
} = useConstants();

const { hasConsent: hasGDPRConsent } = useGDPR();
const licenseValidity = ref<ValidLicenseValue>({
    data: {
        hasGdprConsent: false,
        hasValidLicense: false,
    },
    error: false,
});

export const useContract = () => {
    const fetchContractInfo = async () => {
        if (user.value) {
            const result = await fetchOrRedirect<Contract>(ENDPOINT, {
                body: { companyId: user.value?.user?.company?.id },
                method: 'POST',
            });
            if (!result?.error && result?.data) {
                contract.value = result?.data;
            }
        }
    };

    const hasUserProduct = (): boolean => {
        return !!contract?.value?.attributes?.product?.sku;
    };

    const isProductType = (type: string): boolean => {
        return contract?.value?.attributes?.product?.sku === type;
    };

    const containsProductType = (type: string): boolean => {
        return (
            contract?.value?.attributes?.product?.sku.includes(type) ?? false
        );
    };

    const isProductTypeContingent = (): boolean => {
        return hasUserProduct() && containsProductType(PRODUCT_TYPE_CONTINGENT);
    };

    const setProductContingent = async (): Promise<void> => {
        contingentLoading.value = true;
        await fetchContractInfo();
        hasProductContingentAvailable.value =
            isProductTypeContingent() && isQuantityAvailable();
        contingentLoading.value = false;
    };

    const hasUserCvDatabaseAccess = (): boolean => {
        return !!user?.value?.user?.hasCvDatabaseAccess;
    };

    const isProductDateValid = (): boolean => {
        const productEndDate = contract?.value?.attributes?.endDate;
        return !!(
            productEndDate &&
            new Date(productEndDate).setHours(0, 0, 0, 0) >=
                new Date(Date.now()).setHours(0, 0, 0, 0)
        );
    };

    const hasUserCollaborationFeatures = (): boolean => {
        return (
            (isProductType(PRODUCT_TYPE_MULTI) ||
                isProductType(PRODUCT_TYPE_POWER)) &&
            hasUserCvDatabaseAccess()
        );
    };

    const hasUserPowerAccess = (): boolean => {
        return (
            (isProductType(PRODUCT_TYPE_SINGLE) ||
                isProductType(PRODUCT_TYPE_MULTI) ||
                isProductType(PRODUCT_TYPE_POWER)) &&
            hasUserCvDatabaseAccess()
        );
    };

    const isQuantityAvailable = () => {
        return !!contract.value?.attributes?.quantityAvailable;
    };

    const checkIfQuantityIsEnough = (value: number): boolean => {
        if (contract.value?.attributes?.quantityAvailable !== undefined) {
            return contract.value?.attributes?.quantityAvailable >= value;
        }
        return false;
    };

    const setLicenseValidity = async (
        userData: ExternalUser | null,
    ): Promise<void> => {
        user.value = userData;
        if (user.value) {
            const gdprConsent = await hasGDPRConsent();
            licenseValidity.value.error = gdprConsent.error;
            licenseValidity.value.data.hasGdprConsent = !gdprConsent.error
                ? !!gdprConsent.data?.hasConsent
                : false;
            if (!licenseValidity.value.error) {
                await fetchContractInfo();
                licenseValidity.value.data.hasValidLicense =
                    !!gdprConsent.data?.hasConsent &&
                    hasUserProduct() &&
                    isProductDateValid() &&
                    (isProductTypeContingent() || hasUserPowerAccess());
            }
        } else {
            licenseValidity.value = {
                data: {
                    hasGdprConsent: false,
                    hasValidLicense: false,
                },
                error: false,
            };
        }
    };

    return {
        checkIfQuantityIsEnough,
        contingentLoading,
        contract,
        fetchContractInfo,
        hasProductContingentAvailable,
        hasUserCollaborationFeatures,
        hasUserCvDatabaseAccess,
        hasUserPowerAccess,
        hasUserProduct,
        isProductDateValid,
        isProductType,
        isProductTypeContingent,
        isQuantityAvailable,
        licenseValidity,
        setLicenseValidity,
        setProductContingent,
    };
};
